import React from 'react'
import { Link } from 'gatsby'

import {
  Heading,
  Text,
  Flex,
  Box,
  Button,
  Card,
  Image
} from 'rebass'

import deepDive from '../images/deep_dive.png'
import reversalPlan from '../images/reversal_plan.png'
import celebrateHealth from '../images/celebrate_health.png'

import monthlyPlan from '../images/monthly_plan.jpg'
import deepDivePlan from '../images/deep_dive_plan.jpg'
import reversityUniversity from '../images/reversity_university.jpg'
import heroIllustration from '../images/happypatient2.jpg'

import FreeTrialModal from '../components/freeTrialModal'
import Layout from '../components/layout'
import Container from '../components/container'
import BottomHero from '../components/bottomHero'

const IndexPage = () => (
  <Layout>
    <Container>
      {/* Hero */}
      <Flex flexWrap='wrap' p={[2, 3]}>
        <Box px={2} width={[1, 1/2]}>
          <Heading py={3} fontSize={5}>Reverse diabetes and lose weight with a clinically-proven approach</Heading>
          <Text mb={2} fontSize={3} color='gray'>Start your journey to health and wellness today.</Text>
          <FreeTrialModal />
        </Box>
        <Box px={2} width={[1, 1/2]}>
          <Image py={2} src={heroIllustration} alt='Ketogenic foods'/>
        </Box>
      </Flex>
    </Container>

    {/* How it works */}
    <Box py={5} bg='lightGray'>
      <Container>
        <Flex flexWrap='wrap' py={4}>
          <Box mx='auto'>
            <Text fontWeight='bold' fontSize={5}>How Reversity works</Text>
          </Box>
        </Flex>

        <Flex alignItems='baseline' justifyContent='center' flexWrap='wrap' p={[2, 3]}>
          <Box width={[1, 1/3]} p={2}>
            <Image src={deepDive} height={75} alt='Magnifying glass'/>
            <Text fontSize={4} pt={3}>Take a deep dive</Text>
            <Text color='gray' py={1}>Work 1:1 with our founder, Dr. Harris, to analyze your diet and how it impacts your health.</Text>
          </Box>

          <Box width={[1, 1/3]} p={2}>
            <Image src={reversalPlan} height={75} alt='Two characters discussing'/>
            <Text fontSize={4} pt={3}>Start your reversal program</Text>
            <Text color='gray' py={1}>Change the way that you look at your food. No calorie counting required!</Text>
          </Box>

          <Box width={[1, 1/3]} p={2}>
            <Image src={celebrateHealth} height={75} alt='Two characters celebrating'/>
            <Text fontSize={4} pt={3}>Celebrate your health</Text>
            <Text color='gray' py={1}>Lose weight and see your sugars stabilize. Cut down or eliminate medications!</Text>
          </Box>
        </Flex>
      </Container>
    </Box>

    <Container>
      {/* Testimonial */}
      <Box py={5}>
        <Flex flexWrap='wrap' px={[2, 3]} py={4}>
          <Heading px={2} fontSize={5}>"I've been using Reversity to help me lower my A1C. I'm very fortunate to have found the down-to-earth, knowledgeable team at Reversity to help me achieve my goals. My A1C went from a 6.1 to a 5.6!"
          </Heading>
          <Box pt={3} px={2} width={1}>
            <Text fontSize={3}>
              Elaine
            </Text>
          </Box>
          <Box px={2} width={1}>
            <Text color='gray'>
              New Jersey
            </Text>
          </Box>
        </Flex>
      </Box>
    </Container>

    {/* Plans */}
    <Box bg='lightGray' py={5}>
      <Flex flexWrap='wrap' py={3}>
        <Box mx='auto'>
          <Text fontWeight='bold' fontSize={5}>Programs</Text>
        </Box>
      </Flex>

      <Container>
        <Flex flexWrap='wrap' py={4}>
          <Box width={[1, 1/3]} p={2}>
            <Card
              bg='white'
              variant='primary'
              fontSize={3}>
              <Image src={monthlyPlan} width={1} alt='Mobile phone and healthy food' />
              <Box py={3}>
                <Text textAlign='center'>Custom Reversity Program</Text>
                <Text fontSize={2} color='gray' textAlign='center'>$199.99/month</Text>
              </Box>
              <Link to='/plans/custom-reversity-plan'>
                <Button variant='fullLength'>Learn more</Button>
              </Link>
            </Card>
          </Box>
          <Box width={[1, 1/3]} p={2}>
            <Card
              bg='white'
              variant='primary'
              fontSize={3}>
              <Image src={deepDivePlan} width={1} alt='Mobile phone and healthy food' />
              <Box py={3}>
                <Text textAlign='center'>Single Deep Dive</Text>
                <Text fontSize={2} color='gray' textAlign='center'>$149.99 one time</Text>
              </Box>
              <Link to='/plans/one-time-deep-dive'>
                <Button variant='fullLength'>Learn more</Button>
              </Link>
            </Card>
          </Box>
          <Box width={[1, 1/3]} p={2}>
            <Card
              bg='white'
              variant='primary'
              fontSize={3}>
              <Image src={reversityUniversity} width={1} alt='Mobile phone and healthy food' />
              <Box py={3}>
                <Text textAlign='center'>Reversity University</Text>
                <Text fontSize={2} color='gray' textAlign='center'>$49.99/month</Text>
              </Box>
              <Link to='/plans/reversity-university'>
                <Button variant='fullLength'>Learn more</Button>
              </Link>
            </Card>
          </Box>
        </Flex>
      </Container>
    </Box>
    <BottomHero />
  </Layout>
)

export default IndexPage
